<template>
  <div class="signup-body">
    <div class="error container">
      <div class="row align-items-center">
        <div class="col-md-6 px-5">
          <img src="/images/citronworks_500.png" class="w-100">
        </div>
        <div class="col-md-6">
          <h1 class="bold mb-3">Internal Server Error</h1>
          <h5>Oops, something went wrong. The server encountered an internal error or misconfiguration and was unable to complete your request.</h5>
          <h5>Click here to go back or contact our support to report this.</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style>
  .error h5 {
    line-height: 1.4;
    font-size: 1.1rem;
  }
</style>